<template>
  <v-row class="d-flex justify-center">
    <v-col lg="10" xl="10">
      <v-tabs gitcenter class="mb-15 mt-15">
        <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab }}</v-tab>

        <v-tab-item v-for="(tabItem, k) in tabsItem" :key="k">
          <v-row justify="center">
            <TemplatesItem
              sm="4"
              md="3"
              v-for="(card, n) in tabItem"
              :key="n"
              :card="card"
            >
            </TemplatesItem>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import TemplatesItem from "./TemplatesItem";

export default {
  props: ["tabs", "tabsItem"],
  components: {
    TemplatesItem,
  },
};
</script>

<style>
.v-tabs-items {
  padding: 40px;

  background-color: #e5e5e5 !important;
}
.v-slide-group__content {
  /* margin: 0 10px; */
  border-top: 10px solid #000000;
  border-bottom: 10px solid #000000;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
  background-color: #000000 !important;
}
.v-tab {
  max-width: 200px;
  width: 100%;
  background-color: #000000 !important;
  color: #e5e5e5 !important;
  font-weight: 1000;
  font-size: 16px;
}

.v-tab--active {
  color: #000000 !important;
  font-weight: 1000;
  background-color: azure !important;
}
.v-tabs-bar .v-tab::before {
  display: none;
}
.v-tabs-slider {
  display: none !important;
}
@media (max-width: 900px) {
  .v-tabs-bar {
    height: auto !important;
  }
  .v-slide-group__content {
    flex-direction: column;
    /* flex-wrap: wrap !important; */
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
  .v-tab {
    height: 44px;
    max-width: 120px !important;
    width: 100%;
  }
  .theme--light.v-tabs > .v-tabs-bar {
    padding: 10px 0;
  }
}
@media (max-width: 500px) {
  .v-slide-group__content {
    grid-template-columns: 1fr;
  }
  .v-tab {
    max-width: 100% !important;
  }
  .theme--light.v-tabs > .v-tabs-bar {
    padding: 0;
  }
}
</style>