<template>
  <v-col sm="2" md="2">
    <v-card
      :to="{
        name: 'Edit',
        query: {
          source: 'template',
          chart_id: card.chart_id,
        },
      }"
      flat
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        border: 1px solid grey;
        min-height: 160px;
        width: 120px;
      "
    >
      <!--       <v-card-title>{{ card.title }}</v-card-title>
 -->
      <v-img
        contain
        style="max-height: 60px"
        class="mx-5 mt-5"
        :src="card.img"
      ></v-img>

      <v-card-text class="text-h8 mb-0 mt-0 font-weight-bold text-center">
        {{ card.button }}
      </v-card-text>

      <!-- card is no clickalbe, commented text below no longer required -->
      <!-- <v-card-actions>
        <router-link
          style="text-decoration: none; color: inherit"
          :to="{
            name: 'Edit',
            query: {
              source: 'template',
              chart_id: card.chart_id,
            },
          }"
        >
          <v-btn color="#EEEEED" depressed style="margin: 20px; padding: 0 20">
            {{ card.button }}
          </v-btn>
        </router-link>
      </v-card-actions> -->
      <!-- card is no clickalbe, commented text above no longer required -->
    </v-card>
  </v-col>
</template>

<script>
import IconsAuthor from "./IconsAuthor";

export default {
  name: "TemplatesItem",
  props: ["card"],
  data() {
    return {};
  },
  methods: {},
  components: {
    IconsAuthor,
  },
};
</script>