<template>
  <div class="background" id="template_selector">
    <!-- Elena Methode 3 -->

    <v-container>
      <v-row class="mt-5">
        <v-col cols="12" sm="6">
          <h1 left class="text-left">New Size Chart</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-list-item-title
            class="text-wrap text-left text-h8 font-weight-regular"
          >
            Select the starting template that matches your needs. Some templates
            may contain pre-filled data. Please, make sure that you add, delete
            or modify the data inside the template to match the sizing of your
            garnment.
          </v-list-item-title>
        </v-col>
      </v-row>
      <Tabs :tabs="tabs" :tabsItem="tabsItem"></Tabs>
      <v-row>
        <v-col>
          <v-list-item-title
            class="text-wrap text-center text-h8 font-weight-regular"
          >
            <a
              href="https://www.flaticon.com/free-icons/person"
              title="person icons"
              style="text-decoration: none; color: darkgrey; font-size: 14px"
            >
              -- Amazing icons courtesy of Vitaly and Flaticon 🤍 --
            </a>
          </v-list-item-title>
        </v-col>
      </v-row>
      <SmartSizeFooter></SmartSizeFooter>

      <!--       <IconsAuthor></IconsAuthor>
 -->
    </v-container>
  </div>
</template>

<script>
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

import IconsAuthor from "@/components/Templates/IconsAuthor";
import Tabs from "@/components/Templates/tabs";

export default {
  name: "TemplateSelector",
  components: {
    IconsAuthor,
    SmartSizeFooter,
    Tabs,
  },
  beforeMount() {
    this.fetchtabs();
  },
  data() {
    return {
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      tabs: [],
      tabsItem: [],
      /* tabs: ["Blank", "Woman", "Men", "Girl", "Boy", "Baby", "Pet"],
      tabsItem: [
        //  Blank Array
        [
          {
            img: require("@/assets/classy.svg"),
            button: "Classy",
            chart_id: 11,
          },
          {
            img: require("@/assets/casual.svg"),
            button: "Casual",
            chart_id: 12,
          },
          {
            img: require("@/assets/sporty.svg"),
            button: "Sporty",
            chart_id: 13,
          },
          {
            img: require("@/assets/soft.svg"),
            button: "Soft",
            chart_id: 14,
          },
          {
            img: require("@/assets/blank.svg"),
            button: "Blank canvas",
            chart_id: 10,
          },
        ],
        //  Women Array
        [
          {
            img: require("@/assets/woshirt.svg"),
            button: "Top",
            chart_id: 20,
          },
          {
            img: require("@/assets/017-pants.svg"),
            button: "Bottom",
            chart_id: 21,
          },
          {
            img: require("@/assets/001-shirt.svg"),
            button: "Shirt",
            chart_id: 22,
          },
          {
            img: require("@/assets/woblazer.svg"),
            button: "Blazer",
            chart_id: 23,
          },
          {
            img: require("@/assets/032-brief.svg"),
            button: "Brief",
            chart_id: 24,
          },
          {
            img: require("@/assets/035-brassiere.svg"),
            button: "Bra",
            chart_id: 25,
          },
          {
            img: require("@/assets/046-swimsuit.svg"),
            button: "Swimsuit",
            chart_id: 26,
          },
          {
            img: require("@/assets/042-dress.svg"),
            button: "Dress",
            chart_id: 27,
          },
          {
            img: require("@/assets/044-skirt.svg"),
            button: "Skirt",
            chart_id: 28,
          },
          {
            img: require("@/assets/038-gloves.svg"),
            button: "Gloves",
            chart_id: 29,
          },
          {
            img: require("@/assets/036-hat.svg"),
            button: "Hat",
            chart_id: 30,
          },
          {
            img: require("@/assets/029-highheels.svg"),
            button: "Shoes",
            chart_id: 31,
          },
          {
            img: require("@/assets/socks.svg"),
            button: "Socks",
            chart_id: 32,
          },
        ],
        //  Men Array
        [
          {
            img: require("@/assets/008-tshirt.svg"),
            button: "Top",
            chart_id: 40,
          },
          {
            img: require("@/assets/017-pants.svg"),
            button: "Bottom",
            chart_id: 41,
          },
          {
            img: require("@/assets/001-shirt.svg"),
            button: "Shirt",
            chart_id: 42,
          },
          {
            img: require("@/assets/004-blazer.svg"),
            button: "Blazer",
            chart_id: 43,
          },
          {
            img: require("@/assets/031-underwear.svg"),
            button: "Underwear",
            chart_id: 44,
          },
          {
            img: require("@/assets/027-shoes.svg"),
            button: "Shoes",
            chart_id: 45,
          },
          {
            img: require("@/assets/socks.svg"),
            button: "Socks",
            chart_id: 46,
          },
          {
            img: require("@/assets/038-gloves.svg"),
            button: "Gloves",
            chart_id: 47,
          },
          {
            img: require("@/assets/037-hat.svg"),
            button: "Hat",
            chart_id: 48,
          },
        ],
        //  Girl Array
        [
          {
            img: require("@/assets/girltshirt.svg"),
            button: "Top",
            chart_id: 60,
          },
          {
            img: require("@/assets/girlpants.svg"),
            button: "Bottom",
            chart_id: 61,
          },
          {
            img: require("@/assets/001-shirt.svg"),
            button: "Shirt",
            chart_id: 62,
          },
          {
            img: require("@/assets/032-brief.svg"),
            button: "Brief",
            chart_id: 63,
          },
          {
            img: require("@/assets/dress.svg"),
            button: "Dress",
            chart_id: 64,
          },
          {
            img: require("@/assets/044-skirt.svg"),
            button: "Skirt",
            chart_id: 65,
          },
          {
            img: require("@/assets/sneakers.svg"),
            button: "Shoes",
            chart_id: 66,
          },
          {
            img: require("@/assets/socks.svg"),
            button: "Socks",
            chart_id: 67,
          },
          {
            img: require("@/assets/babyhat.svg"),
            button: "Hat",
            chart_id: 68,
          },
          {
            img: require("@/assets/038-gloves.svg"),
            button: "Gloves",
            chart_id: 69,
          },
        ],
        //  Boy Array
        [
          {
            img: require("@/assets/boytshirt.svg"),
            button: "Top",
            chart_id: 80,
          },
          {
            img: require("@/assets/jeans.svg"),
            button: "Bottom",
            chart_id: 81,
          },
          {
            img: require("@/assets/001-shirt.svg"),
            button: "Shirt",
            chart_id: 82,
          },
          {
            img: require("@/assets/031-underwear.svg"),
            button: "Underwear",
            chart_id: 83,
          },
          {
            img: require("@/assets/sneakers.svg"),
            button: "Shoes",
            chart_id: 84,
          },
          {
            img: require("@/assets/socks.svg"),
            button: "Socks",
            chart_id: 85,
          },
          {
            img: require("@/assets/babyhat.svg"),
            button: "Hat",
            chart_id: 86,
          },
          {
            img: require("@/assets/038-gloves.svg"),
            button: "Gloves",
            chart_id: 87,
          },
        ],
        //  Baby Array
        [
          {
            img: require("@/assets/onesie.svg"),
            button: "Body",
            chart_id: 100,
          },
          {
            img: require("@/assets/pajama.svg"),
            button: "Pajamas",
            chart_id: 101,
          },
          {
            img: require("@/assets/babytop.svg"),
            button: "Top",
            chart_id: 102,
          },
          {
            img: require("@/assets/babypants.svg"),
            button: "Bottom",
            chart_id: 103,
          },
          {
            img: require("@/assets/babydress.svg"),
            button: "Dress",
            chart_id: 104,
          },

          {
            img: require("@/assets/babyshoes.svg"),
            button: "Shoes",
            chart_id: 105,
          },
          {
            img: require("@/assets/babysocks.svg"),
            button: "Socks",
            chart_id: 106,
          },
          {
            img: require("@/assets/babyhat.svg"),
            button: "Hat",
            chart_id: 107,
          },
          {
            img: require("@/assets/babymittens.svg"),
            button: "Mittens",
            chart_id: 108,
          },
        ],
        //  Pet Array
        [
          {
            img: require("@/assets/dogcollar.svg"),
            button: "Dog Collar",
            chart_id: 120,
          },
          {
            img: require("@/assets/dogcover.svg"),
            button: "Dog Hoodie",
            chart_id: 121,
          },
        ],
        //  Other Array
      ], */
    };
  },
  methods: {
    async fetchtabs() {
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/template/all", {
          params: { type: "size" },
        })
        .then((response) => (this.data = response.data))
        .catch((error) => console.log(error));

      console.log("fetchtabs on Templates.vue: ", this.data);

      this.tabs = [];
      this.tabsItem = [];
      this.charts = [];
      this.data.forEach((fetchtab) => {
        let tablist = [];
        this.tabs.push(fetchtab.name);
        fetchtab.charts.forEach((item) => {
          item = item.chart;
          this.charts.push(item);

          console.log("item.media:", item.media);

          tablist.push({
            img: require("@/assets/" + item.media),
            button: item.name,
            chart_id: item.id,
          });
        });
        this.tabsItem.push(tablist);
      });
      //localStorage.setItem("charts", JSON.stringify(this.charts));
      console.log(`Templates.vue this.charts = ${this.charts}`);

      this.$store.dispatch("updateTemplates", this.charts);
    },
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>