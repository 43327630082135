<template>
  <div class="icons-credit">
    <h5>
      Icons made by Freepik<v-icon x-small color="red" right>
        mdi-heart
      </v-icon>
    </h5>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icons-credit {
  position: fixed;
  left: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(244, 245, 244);
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  h5 {
    color: rgb(58, 53, 53);
    font-size: 12px;
  }
}
</style>